import React from 'react';

import { ArticleCardProps  } from '../../../types';

import ExternalLink from '../../../svgs/UI/ExternalLink';
import CustomImage from '../../atoms/CustomImage';
import Styled from './index.styled';

const ArticleCard = ({
	link,
	title,
	subtitle,
	description,
	image,
	isExternalLink,
	theme,
}: ArticleCardProps) => (
		<Styled.Article
			href={link}
			target={isExternalLink ? '_blank' : ''}
		>
			<CustomImage {...image} width="300" height="250" />
			<Styled.Article__Subtitle>
				{subtitle}
			</Styled.Article__Subtitle>
			<Styled.Article__Title>
				{title}
				{isExternalLink && (
					<ExternalLink fill={theme.primaryForeground} />
				)}
			</Styled.Article__Title>

			<Styled.Article__Description>
				{description}
			</Styled.Article__Description>
		</Styled.Article>
	);

export default ArticleCard;
