import styled, { css } from 'styled-components';
import {
	smBodyBook,
	xxlTitleRegular,
} from '../../atoms/Typography/index.styled';
import { gap } from '../../../styles/settings/spacing';
import { Container } from '../../../styles/generic/container';
import breakpoints from '../../../styles/settings/breakpoints';
import { above } from '../../../styles/tools/media';

const HeroCarousel__Container = styled.div`
	position: relative;
`;

const HeroCarousel__Images = styled.div`
	position: relative;
	overflow: hidden;
	height: 180px;

	${above(
		breakpoints[600],
		() => css`
			height: 400px;
		`
	)}

	${above(
		breakpoints[960],
		() => css`
			height: 700px;
		`
	)}
`;

const HeroCarousel__ImagesTrack = styled.div<{ currentIndex: number }>`
	position: absolute;
	height: 100%;
	display: flex;
	flex-wrap: nowrap;
	left: ${props => `${props.currentIndex * -100}vw`};
	transform: translateX(-3rem);
	transition: left 0.6s cubic-bezier(0.075, 0.82, 0.165, 1);
	${above(
		breakpoints[960],
		() => css`
			transform: translateX(-5rem);
		`
	)}
`;

const HeroCarousel__Image = styled.div`
	position: relative;
	width: 100vw;
	height: 100%;
	border-right: 1rem solid white;

	picture {
		position: absolute;
		top: 0;
		left: 0;
		height: 100%;
		width: 100%;
	}

	${above(
		breakpoints[960],
		() => css`
			border-width: 1.5rem;
		`
	)}
`;

const HeroCarousel__NextButton = styled.button`
	border: none;
	background-color: white;
	display: flex;
	align-items: center;
	justify-content: center;
	color: var(--black);
	padding: 0;
	width: 2rem;
	height: 2rem;
	position: absolute;
	z-index: 10;
	top: calc(50% - 1rem);
	left: calc(-4.5rem + 100vw);

	svg {
		width: 1.3rem;
	}

	&:focus {
		outline: 2px solid var(--black);
	}

	${above(
		breakpoints[600],
		() => css`
			width: 3rem;
			height: 3rem;
			left: calc(-5rem + 100vw);
		`
	)}

	${above(
		breakpoints[960],
		() => css`
			width: 4rem;
			height: 4rem;
			left: calc(-7.75rem + 100vw);

			svg {
				width: 2rem;
			}
		`
	)}
`;

const HeroCarousel__Content = styled.div`
	${above(
		breakpoints[960],
		() => css`
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
		`
	)}
`;

const HeroCarousel__ContentLimitedWidth = styled(Container)`
	${above(
		breakpoints[960],
		() => css`
			height: 100%;
			position: relative;
		`
	)}
`;

const HeroCarousel__ContentInner = styled.div`
	padding: ${gap[20]};
	text-align: center;

	${above(
		breakpoints[600],
		() => css`
			padding: ${gap[36]};
		`
	)}

	${above(
		breakpoints[960],
		() => css`
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			background-color: var(--white);
			max-width: 600px;
			padding: ${gap[48]} ${gap[24]};
		`
	)}
`;

const HeroCarousel__Heading = styled(xxlTitleRegular)`
	margin-bottom: ${gap[24]};
`;

const HeroCarousel__P = styled(smBodyBook)`
	max-width: 63vw;
	margin-left: auto;
	margin-right: auto;
	margin-bottom: ${gap[24]};
	${above(
		breakpoints[960],
		() => css`
			margin-bottom: ${gap[32]};
		`
	)}
`;

const HeroCarousel__Buttons = styled.div`
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	align-items: center;
	justify-content: center;
`;

const HeroCarousel__Button = styled.div`
	--primaryForeground: black;
	margin: 0 ${gap[16]} ${gap[8]};
	svg {
		width: 1.5rem;
	}

	${above(
		breakpoints[960],
		() => css`
			svg {
				width: 2rem;
			}
		`
	)}
`;

export default {
	HeroCarousel__Container,
	HeroCarousel__Images,
	HeroCarousel__ImagesTrack,
	HeroCarousel__Image,
	HeroCarousel__NextButton,
	HeroCarousel__Content,
	HeroCarousel__ContentLimitedWidth,
	HeroCarousel__ContentInner,
	HeroCarousel__Heading,
	HeroCarousel__P,
	HeroCarousel__Buttons,
	HeroCarousel__Button,
};
