import styled, { css } from 'styled-components';
import { Container } from '../../../styles/generic/container';
import breakpoints from '../../../styles/settings/breakpoints';
import { gap, spacing } from '../../../styles/settings/spacing';
import { above } from '../../../styles/tools/media';
import { mdTitleRegular } from '../../atoms/Typography/index.styled';

const Content__Filters = styled.section`
	position: relative;
	padding-top: ${spacing[48]};
	padding-bottom: ${spacing[48]};
	background-color: var(--secondaryBackground);

	&::after {
		content: '';
		position: absolute;
		bottom: -16px;
		left: 50%;
		width: 0;
		height: 0;
		border: 0 solid transparent;
		border-left-width: 16px;
		border-right-width: 16px;
		border-top: 16px solid var(--secondaryBackground);
		transform: translateX(-50%);
		z-index: 10;
	}

	${above(breakpoints[600], () => css`
		&::after {
			bottom: -24px;
			border-left-width: 24px;
			border-right-width: 24px;
			border-top-width: 24px;
		}
	`)}

	${above(breakpoints[1440], () => css`
		padding-top: ${spacing[56]};
		padding-bottom: ${spacing[56]};
	`)}
`;

const Content__Filters__Inner = styled(Container)`
	display: flex;
	flex-direction: column;

	& > div, & > p {
		margin-bottom:  ${gap[24]};

		& > label {
			color: var(--primaryForeground);
		}
	}

	${above(breakpoints[960], () => css`
		flex-direction: row;
		align-items: flex-end;

		& > div {
			flex: 1;
			margin-bottom: 0;
			margin-left: ${gap[20]};
		}

		& > p {
			flex: none;
			margin-bottom: ${gap[8]};
		}
	`)}
`;

const Content__Title = styled(mdTitleRegular)`
	margin-bottom: ${gap[24]};
	color: var(--primaryForeground);
	margin-bottom: 0;

	${above(breakpoints[960], () => css`
		margin-bottom: 0;
	`)}
`;

const Content__Articles = styled(Container)`
	padding-top: ${spacing[48]};
	padding-bottom: ${spacing[48]};

	${above(breakpoints[1440], () => css`
		padding-top: ${spacing[80]};
		padding-bottom: ${spacing[80]};
	`)}
`;

const Content__Articles_Grid = styled(Container)`
	display: grid;
    grid-gap: ${gap[32]};;
	grid-template-columns: 1fr;

	${above(breakpoints[768], () => css`
		grid-gap: ${gap[40]};;
    	grid-template-columns: 1fr 1fr;
	`)}

	${above(breakpoints[1440], () => css`
		grid-gap: ${gap[40]};;
    	grid-template-columns: 1fr 1fr 1fr;
	`)}
`;

const Content__Articles__Message = styled(mdTitleRegular)`
	padding: ${spacing[48]};
	display: flex;
	align-items: center;
	justify-content: center;
`;

const Content_LoadMore = styled.div`
	display: flex;
	justify-content: center;

	padding-bottom: ${spacing[48]};

	${above(breakpoints[1440], () => css`
		padding-bottom: ${spacing[80]};
	`)}
`;

export default {
	Content__Filters,
	Content__Filters__Inner,
	Content__Title,
	Content__Articles,
	Content__Articles_Grid,
	Content__Articles__Message,
	Content_LoadMore,
};
