import React from 'react';
import SVG from '../../atoms/SVG';
import LinkStandard from '../../atoms/LinkStandard';
import Styled from './index.styled';

type CardsBlock = {
	title: string;
	cards: Array<CardTypes>;
};

type CardTypes = {
	id: string;
	icon: string;
	titleLines: Array<string>;
	subTitle: string;
	copy: string;
	link?: LinkTypes;
};

type LinkTypes = {
	url: string;
	title: string;
	dataGACategory: string;
	dataGAAction: string;
	dataGALabel: string;
};

const renderCard = (data: CardTypes) => (
	<Styled.OfficeOptions__CardsItem key={data.id} hasLink={!!data.link}>
		<SVG name={data.icon} />
		{data.titleLines.map((line: string, index: number) => (
			<Styled.OfficeOptions__CardTitle key={index}>{line}</Styled.OfficeOptions__CardTitle>
		))}
		<Styled.OfficeOptions__CardSubTitle>{data.subTitle}</Styled.OfficeOptions__CardSubTitle>
		<Styled.OfficeOptions__CardCopy>{data.copy}</Styled.OfficeOptions__CardCopy>

		{data.link && (
			<Styled.OfficeOptions__CardLink>
				<LinkStandard {...data.link} linkIcon="subDirectoryDown" />
			</Styled.OfficeOptions__CardLink>
		)}
	</Styled.OfficeOptions__CardsItem>
);

const OfficeOptions = ({ title, cards }: CardsBlock) => (
	<Styled.OfficeOptions>
		<Styled.OfficeOptions__Title as="h2">{title}</Styled.OfficeOptions__Title>
		<Styled.OfficeOptions__Cards as="ul">{cards.map(renderCard)}</Styled.OfficeOptions__Cards>
	</Styled.OfficeOptions>
);

export default OfficeOptions;
