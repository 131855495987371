import React from 'react';
import Styled from './index.styled';

export type DropdownProps = {
	id: string
	label: string
	name: string
	options?: DropdownOption[]
	required?: boolean
	defaultValue?: string
	onChange?: (event: Event) => void
};

export type DropdownOption = {
	value?: string
	filter?: string
	displayText: string
};

const Dropdown = (props: DropdownProps) => {
	const { id, name, label, options, defaultValue, onChange, required } = props;

	const handleChange = evt => {
		if (onChange) {
			onChange(evt);
		}
	};

	return (
		<Styled.select>
			<Styled.select__label as="label" htmlFor={id}>
				{label}
			</Styled.select__label>

			<Styled.select__optionContainer>
				<Styled.select__select
					as="select"
					id={id}
					name={name}
					defaultValue={defaultValue}
					onChange={handleChange}
					required={required}
				>
					{options && options.map((dropdownOption, i) => (
						<option
							value={dropdownOption.value ?? dropdownOption.filter}
							key={i}
						>
							{dropdownOption.displayText}
						</option>
					))}
				</Styled.select__select>
			</Styled.select__optionContainer>
		</Styled.select>
	);
};

export default Dropdown;
