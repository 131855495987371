import React from 'react';

import { ButtonProps } from '../../../types';

import SVG from '../SVG';
import Styled from './index.styled';


const getButtonType = buttonType => {
	switch (buttonType) {
		case 'primary':
			return Styled.ButtonPrimary;
		case 'secondary':
			return Styled.ButtonSecondary;
		case 'tertiary':
			return Styled.ButtonTertiary;
		case 'special-banner':
			return Styled.Button__SpecialBanner;
		default:
			return Styled.ButtonPrimary;
	}
};

const Button = ({
	buttonText,
	buttonLink,
	buttonCategory,
	buttonAction,
	buttonLabel,
	as = 'a',
	buttonType = 'primary',
	callback,
	iconName,
	iconSize = 'normal',
	loading = false,
	loadingText = 'Sending',
	target,
	type,
	rel,
	iconPosition = 'left',
}: ButtonProps) => {
	const ButtonStyle = getButtonType(buttonType);
	const typeOfButton = as === 'button' ? 'button' : as;

	const ButtonInner = () => {
		if (loading) {
			return <Styled.Button__Loading>{loadingText}</Styled.Button__Loading>;
		}

		return (
			<>
				{iconName && <SVG name={iconName} />}
				{buttonText && buttonText}
			</>
		);
	};

	return (
		<ButtonStyle
			as={typeOfButton}
			href={buttonLink}
			data-ga-category={buttonCategory}
			data-ga-action={buttonAction}
			data-ga-label={buttonLabel}
			target={target}
			rel={rel}
			onClick={callback}
			iconSize={iconSize}
			type={type}
			iconPosition={iconPosition}
		>
			<ButtonInner />
		</ButtonStyle>
	);
};

export default Button;
