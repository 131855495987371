import styled, {css, keyframes} from 'styled-components';
import {smBodyRegular} from '@/components/atoms/Typography/index.styled';
import breakpoints from '@/styles/settings/breakpoints';
import {gap} from '@/styles/settings/spacing';
import {above, hover} from '@/styles/tools/media';

type ButtonProps = {
	iconPosition: string
};

const Button = styled(smBodyRegular)<ButtonProps>`
	position: relative;
	display: inline-flex;
	flex-direction: ${(props: ButtonProps) => props.iconPosition === 'left' ? 'row' : 'row-reverse'};
	justify-content: center;
	align-items: center;
	padding: ${gap[8]} ${gap[16]} ${gap[12]};
	border-width: 2px;
	border-style: solid;
	text-align: center;
	text-decoration: none;
	cursor: pointer;
	pointer-events: all;

	& > svg {
		display: block;
		width: 24px;
		height: 24px;
		${(props: ButtonProps) => props.iconPosition === 'left' ? `margin-right: ${gap[16]}` : `margin-left: ${gap[16]}`};
	}

	& path {
		fill: currentColor;
	}

	${above(breakpoints[600], () => css`
		& > svg {
			width: 32px;
			height: 32px;
		}
	`)}

	${above(breakpoints[960], () => css`
		padding: ${gap[8]} ${gap[24]} ${gap[12]};
	`)}
`;

const ButtonPrimary = styled(Button)`
	background-color: var(--primaryForeground);
	color: var(--white);
	border-color: var(--primaryForeground);

	&:after {
		content: '';
		position: absolute;
		right: 0;
		bottom: 0;
		left: 0;
		height: 100%;
		background-color: var(--primaryAccent);
		transition: transform 0.25s cubic-bezier(0.22, 0.61, 0.36, 1);
		z-index: -1;
	}

	&:focus {
		outline: 4px solid var(--primaryAccent);
	}

	${hover(() => css`
		&:hover {
			&:after {
				transform: translate(10px, 10px);
			}
		}
	`)}
`;

const ButtonSecondary = styled(Button)`
	background-color: var(--primaryForeground);
	color: var(--white);
	border-color: var(--primaryForeground);
	transition: all 0.3s ease-in-out;

	&:focus {
		background-color: var(--white);
		border-color: var(--primaryForeground);
		color: var(--primaryForeground);
		outline: 4px solid var(--primaryAccent);
	}

	${hover(() => css`
		&:hover {
			background-color: var(--white);
			border-color: var(--primaryForeground);
			color: var(--primaryForeground);
		}
	`)}
`;

const ButtonTertiary = styled(Button)`
	background-color: transparent;
	color: var(--primaryForeground);
	border-color: var(--primaryForeground);
	transition: all 0.3s ease-in-out;

	&:focus {
		background-color: var(--primaryForeground);
		border-color: var(--primaryForeground);
		color: var(--white);
		outline: 4px solid var(--primaryAccent);
	}

	${hover(() => css`
		&:hover {
			background-color: var(--primaryForeground);
			border-color: var(--primaryForeground);
			color: var(--white);
		}
	`)}
`;

const Button__SpecialBanner = styled(Button)`
	background-color: var(--white);
	color: var(--primaryForeground);
	border-color: transparent;
	transition: all 0.3s ease-in-out;

	&:focus {
		background-color: var(--primaryForeground);
		color: var(--white);
		border-color: transparent;
		outline: 4px solid var(--primaryAccent);
	}

	${hover(() => css`
		&:hover {
			background-color: var(--primaryForeground);
			border-color: var(--primaryForeground);
			color: var(--white);
		}
	`)}
`;

const loading = keyframes`
	0% { content: ''; }
	33% { content: '.'; }
	66% { content: '..'; }
	100% { content: '...'; }
`;

const Button__Loading = styled.span`
	&:after {
		content: '';
		display: inline-block;
		width: 16px;
		text-align: left;
		animation: ${loading} 2s linear infinite;
	}
`;

export default {
	ButtonPrimary,
	ButtonSecondary,
	ButtonTertiary,
	Button__Loading,
	Button__SpecialBanner
};
