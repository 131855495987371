import styled, { css } from 'styled-components';
import breakpoints from '../../../styles/settings/breakpoints';
import { gap } from '../../../styles/settings/spacing';
import { above } from '../../../styles/tools/media';
import { smBodyBook, smBodyRegular } from '../Typography/index.styled';

const select = styled.div`
	margin-bottom: ${gap[24]};

	${above(
		breakpoints[600],
		() => css`
			margin-bottom: ${gap[40]};
		`
	)}

	${above(
		breakpoints[960],
		() => css`
			max-width: 300px;
		`
	)}
`;

const select__label = styled(smBodyRegular)`
	display: block;
	margin-bottom: ${gap[8]};
	color: var(--black);
`;

const select__optionContainer = styled.div`
	position: relative;

	select {
		height: 56px;
	}
`;

const select__select = styled(smBodyBook)`
	display: block;
	width: 100%;
	padding: ${gap[8]} ${gap[52]} ${gap[8]} ${gap[16]};
	border-radius: 4px;
	border: 1px solid var(--grey30);
	outline: none;
	appearance: none;

	&:focus {
		box-shadow: 0px 0px 0px 2px var(--grey15);
	}

	background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20'><path fillRule='evenodd' clipRule='evenodd' d='M7.41 8.59003L12 13.17L16.59 8.59003L18 10L12 16L6 10L7.41 8.59003Z' fill='black' /></svg>")
		no-repeat;
	background-position: calc(100% - 20px) calc(50%);
	background-color: var(--white);
`;

export default {
	select,
	select__label,
	select__optionContainer,
	select__select,
};
