import styled, { css } from 'styled-components';
import breakpoints from '../../../styles/settings/breakpoints';
import { Grid } from '../../../styles/generic/container';
import { gap, spacing } from '../../../styles/settings/spacing';
import { above } from '../../../styles/tools/media';
import { smBodyLight, xlTitleRegular, mdBodyRegular, smBodyBook } from '../../atoms/Typography/index.styled';

const OfficeOptions = styled(Grid)`
	background-color: var(--primaryBackground);

    padding-top: ${spacing[48]};
    padding-bottom: ${spacing[48]};

    ${above(breakpoints[960], () => css`
        padding-top: ${spacing[64]};
        padding-bottom: ${spacing[64]};
    `)}

    ${above(breakpoints[1440], () => css`
        padding-top: ${spacing[80]};
        padding-bottom: ${spacing[80]};
    `)}
`;
const OfficeOptions__Title = styled(xlTitleRegular)`
	color: var(--primaryForeground);
	grid-column: 1 / -1;
	grid-row: 1;
	margin-bottom: ${gap[24]};

	${above(breakpoints[960], () => css`
		margin-bottom: ${gap[32]};
		text-align: center;
	`)}
`;

const OfficeOptions__Cards = styled.ul`
	grid-column: 1 / -1;
	grid-row: 3;
	padding-left: 0;
	list-style-type: none;

	${above(breakpoints[768], () => css`
		display: grid;
		grid-template-columns: 1fr 1fr;
		grid-column-gap: ${gap[24]};
	`)}

	${above(breakpoints[960], () => css`
		grid-column-gap: ${gap[48]};
		grid-template-columns: repeat(12,1fr);
	`)}
`;

interface CardProps {
	hasLink: boolean
}

const OfficeOptions__CardsItem = styled.li<CardProps>`
	background: var(--secondaryBackground);

	margin-bottom: ${gap[32]};
	padding: ${gap[24]} ${gap[16]} ${gap[32]} ${gap[16]};

	display: flex;
    flex-direction: column;

	& svg {
		width: 48px;
		flex-basis: 48px;
		margin-bottom: ${gap[16]};
	}

	& svg > path {
		fill: var(--primaryForeground);
	}

	&:last-child {
		margin-bottom: ${gap[0]};
	}

	${above(breakpoints[960], () => css`
		grid-column: span 4;
		margin-bottom: ${gap[0]};
		padding: ${gap[40]} ${gap[24]};

		& svg {
			width: 64px;
			flex-basis: 64px;
			margin-bottom: ${gap[24]};
		}
	`)}

	${props => props.hasLink && css`
		background: var(--white);
	`}
`;

const OfficeOptions__CardTitle = styled(mdBodyRegular)`
	color: var(--primaryForeground);
`;

const OfficeOptions__CardSubTitle = styled(smBodyBook)`
	margin-bottom: ${gap[24]};
	margin-top: ${gap[12]};

	${above(breakpoints[960], () => css`
		margin-bottom: ${gap[32]};
		margin-top: ${gap[16]};
	`)}
`;

const OfficeOptions__CardCopy = styled(smBodyLight)``;

const OfficeOptions__CardLink = styled.div`
	margin-top: auto;

	& a {
		margin-top: ${gap[24]};
	}

	& svg {
		margin-bottom: 0;
    	margin-left: 0;
	}
`;

export default {
	OfficeOptions,
	OfficeOptions__Title,
	OfficeOptions__Cards,
	OfficeOptions__CardsItem,
	OfficeOptions__CardTitle,
	OfficeOptions__CardSubTitle,
	OfficeOptions__CardCopy,
	OfficeOptions__CardLink
};
