import React from 'react';
import { clientConfig } from '@/env/config';
import pxToRem from './pxToRem';

const config = clientConfig();
const path = config.mediaServer;
const imageSizes = [320, 640, 960, 1280, 1600, 1920];

function setMediaQuery(size, index) {
	if (index === 0) {
		return `(max-width: ${pxToRem(size - 0.063)})`;
	}

	if (index === imageSizes.length - 1) {
		return `(min-width: ${pxToRem(imageSizes[index - 1])})`;
	}

	return `(min-width: ${pxToRem(imageSizes[index - 1])}) and (max-width: ${pxToRem(
		size - 0.063
	)})`;
}

function preloadHeroImage(src: string, imageType = 'jpg') {
	return imageSizes.map((size, index) => {
		const width = 320 * (index + 1);
		let href = `${path}${src}.${imageType}?imageformat=webp&width=${width}`;

		// handle contentful
		if (src.includes('images.ctfassets.net')) {
			href = `${src}?fm=${imageType}&w=${width}`;
		}
		// handle frontify
		if (src.includes('frontify')) {
			href = `${src}?format=webp&width=${size}`;
		}

		return (
			<link
				key={index}
				href={href}
				rel="preload"
				type="image/webp"
				as="image"
				media={setMediaQuery(size, index)}
			/>
		);
	});
}

export default preloadHeroImage;
