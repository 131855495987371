import React, { useState } from 'react';
import Head from 'next/head';

import { ButtonProps } from '../../../types';

import CustomImage from '../../atoms/CustomImage';
import preloadHeroImage from '../../../styles/tools/preloadHeroImage';
import SVG from '../../atoms/SVG';
import Button from '../../atoms/Button';
import Browser from '../../../lib/browser';
import Styled from './index.styled';

interface HeroCarouselImage {
	src: string;
	alt: string;
	imageType?: string;
}

interface HeroCarouselTypes {
	images?: HeroCarouselImage[];
	title?: string;
	paragraphs?: string[];
	buttons?: ButtonProps[];
	anchorId?: string;
}

const getButtonIcon = button => {
	if (button.iconName) {
		return button.iconName;
	}

	if (button.buttonLink && button.buttonLink.length > 0) {
		return button.buttonLink.startsWith('#') ? 'subDirectoryDown' : 'left';
	}

	return 'left';
};

const HeroCarousel = ({
	images = [],
	title,
	paragraphs = [],
	buttons = [],
	anchorId,
}: HeroCarouselTypes) => {
	const [currentIndex, setCurrentIndex] = useState(0);

	return (
		<>
			<Head>{images.map(image => preloadHeroImage(image.src, image.imageType))}</Head>
			<Styled.HeroCarousel__Container id={anchorId || ''}>
				<Styled.HeroCarousel__Images role="slider">
					<Styled.HeroCarousel__ImagesTrack currentIndex={currentIndex}>
						{images.map((image, i) => (
							<Styled.HeroCarousel__Image key={`${image.src}-${i}`}>
								<CustomImage {...image} width="100%" height="auto" />
							</Styled.HeroCarousel__Image>
						))}
					</Styled.HeroCarousel__ImagesTrack>
					<Styled.HeroCarousel__NextButton
						aria-label={`View ${
							currentIndex === images.length - 1 ? 'first' : 'next'
						} image`}
						onClick={() => {
							setCurrentIndex(
								currentIndex === images.length - 1 ? 0 : currentIndex + 1
							);
						}}
					>
						<SVG name="arrowRight" fill="black" />
					</Styled.HeroCarousel__NextButton>
				</Styled.HeroCarousel__Images>
				<Styled.HeroCarousel__Content>
					<Styled.HeroCarousel__ContentLimitedWidth>
						<Styled.HeroCarousel__ContentInner>
							{title && (
								<Styled.HeroCarousel__Heading as="h1">
									{title}
								</Styled.HeroCarousel__Heading>
							)}
							{paragraphs &&
								paragraphs.length > 0 &&
								paragraphs.map((text, i) => (
									<Styled.HeroCarousel__P key={i} as="p">
										{text}
									</Styled.HeroCarousel__P>
								))}
							{!!buttons.length && (
								<Styled.HeroCarousel__Buttons>
									{buttons.map((button, i) => (
										<Styled.HeroCarousel__Button key={i}>
											<Button
												iconPosition="right"
												buttonLink={button.buttonLink}
												buttonText={button.buttonText}
												buttonCategory={button.buttonCategory}
												buttonAction={button.buttonAction}
												buttonLabel={button.buttonLabel}
												buttonType={button.buttonType}
												iconName={getButtonIcon(button)}
												callback={event =>
													Browser.handleClick(event, button.buttonLink)
												}
											/>
										</Styled.HeroCarousel__Button>
									))}
								</Styled.HeroCarousel__Buttons>
							)}
						</Styled.HeroCarousel__ContentInner>
					</Styled.HeroCarousel__ContentLimitedWidth>
				</Styled.HeroCarousel__Content>
			</Styled.HeroCarousel__Container>
		</>
	);
};

export default HeroCarousel;
