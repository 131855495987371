import { CookiesStatic } from 'js-cookie';

interface IConversionTracker {
	sendConversionEvent: () => void;
	saveParams: (incomingParameters: conversionParameters) => void;
}

type conversionParameters = {
	memberId: string;
	jobId: string;
	subId: string;
	list: string;
	originalLinkId: string;
	batchId: string;
};

export class ConversionTracker implements IConversionTracker {
	private _cookieManager;

	constructor(
		cookieManager: CookiesStatic<string> & {
			noConflict?(): CookiesStatic<string>;
		}
	) {
		this._cookieManager = cookieManager;
	}

	sendConversionEvent() {
		const cookies = this._cookieManager.get();
		if (cookies.salesForceTrackingParameter_batchId) {
			const img = document.createElement(`img`);
			img.setAttribute('style', 'display: none');
			img.src = `https://click.s51.exacttarget.com/conversion.aspx?xml=<system><system_name>tracking</system_name><action>conversion</action><BatchID>${cookies.salesForceTrackingParameter_batchId}</BatchID><conversion_link_id>1</conversion_link_id><display_order>1</display_order><sub_id>${cookies.salesForceTrackingParameter_subId}</sub_id><email></email><job_id>${cookies.salesForceTrackingParameter_jobId}</job_id><list>${cookies.salesForceTrackingParameter_list}</list><member_id>${cookies.salesForceTrackingParameter_memberId}</member_id><original_link_id>${cookies.salesForceTrackingParameter_originalLinkId}</original_link_id><link_alias>Test Page</link_alias><data_set></data_set></system>`;
			document.body.appendChild(img);
		}
	}

	saveParams(incomingParameters: conversionParameters) {
		this._cookieManager.set(
			'salesForceTrackingParameter_originalLinkId',
			incomingParameters.originalLinkId
		);
		this._cookieManager.set('salesForceTrackingParameter_jobId', incomingParameters.jobId);
		this._cookieManager.set('salesForceTrackingParameter_subId', incomingParameters.subId);
		this._cookieManager.set('salesForceTrackingParameter_list', incomingParameters.list);
		this._cookieManager.set('salesForceTrackingParameter_batchId', incomingParameters.batchId);
		this._cookieManager.set(
			'salesForceTrackingParameter_memberId',
			incomingParameters.memberId
		);
	}
}
