// API Data
// Data
import React, { createRef, useEffect, useMemo } from 'react';
import { GetStaticPaths } from 'next';
import Script from 'next/script';
import dynamic from 'next/dynamic';
import HttpStatusCode from '@/types/HttpStatusCode';
import Cookies from 'js-cookie';

// Components
import { getSubSite, SubSiteContext } from '@/components/providers/SubSite';
import Content from '@/components/organisms/Content';
import HeroCarousel from '@/components/organisms/HeroCarousel';
import OfficeOptions from '@/components/organisms/OfficeOptions';
import themes from '@/styles/settings/themes';
import Page from '@/components/organisms/Page';
import { OlarkChatBox } from '@/lib/OlarkChatBox';
import { pushSessionIdToDataLayer } from '@/lib/tracking';
import { ConversionTracker } from '@/components/conversionTracker/ConversionTracker';
import { serverConfig } from '@/env/config';

// Components
const Hero = dynamic(() => import('@/components/organisms/Hero'));
const HeroSplit = dynamic(() => import('@/components/organisms/HeroSplit'));
const HeroSvg = dynamic(() => import('@/components/organisms/HeroSvg'));
const USP = dynamic(() => import('@/components/molecules/USP'));
const AmenitiesTitle = dynamic(() => import('@/components/atoms/AmenitiesTitle'));
const CTA = dynamic(() => import('@/components/atoms/CTA'));
const Divider = dynamic(() => import('@/components/atoms/Divider'));
const Carousel = dynamic(() => import('@/components/molecules/Carousel'));
const Gallery = dynamic(() => import('@/components/molecules/Gallery'));
const Guidance = dynamic(() => import('@/components/molecules/Guidance'));
const Location = dynamic(() => import('@/components/molecules/Location'));
const LogoGrid = dynamic(() => import('@/components/molecules/LogoGrid'));
const MultiOptionMediaCta = dynamic(() => import('@/components/molecules/MultiOptionMediaCta'));
const Pioneer = dynamic(() => import('@/components/molecules/Pioneer'));
const SingleUSP = dynamic(() => import('@/components/molecules/SingleUSP'));
const USPText = dynamic(() => import('@/components/molecules/USPText'));
const USPList = dynamic(() => import('@/components/organisms/USPList'));
const Accordion = dynamic(() => import('@/components/organisms/Accordion'));
const AccordionFilter = dynamic(() => import('@/components/organisms/AccordionFilter'));
const Stats = dynamic(() => import('@/components/molecules/Stats'));
const VirtualTourEmbed = dynamic(() => import('@/components/molecules/VirtualTourEmbed'));
const CollageAndText = dynamic(() => import('@/components/organisms/CollageAndText'));
const CompareTable = dynamic(() => import('@/components/organisms/CompareTable'));
const Contact = dynamic(() => import('@/components/organisms/Contact'));
const ContactPardot = dynamic(() => import('@/components/organisms/ContactPardot'));
const BulletedImageCta = dynamic(() => import('@/components/organisms/BulletedImageCta'));
const MarkDownAndImage = dynamic(() => import('@/components/organisms/MarkDownAndImage'));
const Support = dynamic(() => import('@/components/organisms/Support'));
const Tabbed = dynamic(() => import('@/components/organisms/Tabbed'));
const Onward = dynamic(() => import('@/components/organisms/Onward'));
const CardsBlock = dynamic(() => import('@/components/organisms/CardsBlock'));
const TeamBlock = dynamic(() => import('@/components/organisms/TeamBlock'));
const Cards = dynamic(() => import('@/components/organisms/Cards'));
const Package = dynamic(() => import('@/components/organisms/Package'));
const HorizontalCards = dynamic(() => import('@/components/organisms/HorizontalCards'));
const HeroCTA = dynamic(() => import('@/components/organisms/HeroCTA'));
const ContactCards = dynamic(() => import('@/components/organisms/ContactCards'));
const Purpose = dynamic(() => import('@/components/organisms/Purpose'));
const MapWithCards = dynamic(() => import('@/components/molecules/MapWithCards'));
const MailOption = dynamic(() => import('@/components/molecules/MailOption'));
const Article = dynamic(() => import('@/components/organisms/Article'));
const ContentSelection = dynamic(() => import('@/components/organisms/ContentSelection'));
const ArticleFilter = dynamic(() => import('@/components/organisms/ArticleFilter'));
const HeroVideoImage = dynamic(() => import('@/components/organisms/HeroVideoImage'));
const BannerCta = dynamic(() => import('@/components/organisms/BannerCta'));
const CustomEmbed = dynamic(() => import('@/components/molecules/CustomEmbed'));
const DynamicArticleList = dynamic(() => import('@/components/organisms/DynamicArticleList'));
const Sitemap = dynamic(() => import('@/components/organisms/Sitemap'));

type ComponentData = {
	id: string;
	data: any;
	type: string;
};

const Slug = ({ data }: { data: any; sendPageData: any }) => {
	if (typeof window !== 'undefined') {
		const windowUrl = new URL(window.location.href);
		const salesforceTrackingParameters = {
			memberId: windowUrl.searchParams.get('mid'),
			jobId: windowUrl.searchParams.get('j'),
			subId: windowUrl.searchParams.get('sfmc_sub'),
			list: windowUrl.searchParams.get('l'),
			originalLinkId: windowUrl.searchParams.get('u'),
			batchId: windowUrl.searchParams.get('jb'),
		};

		if (
			salesforceTrackingParameters.memberId &&
			salesforceTrackingParameters.jobId &&
			salesforceTrackingParameters.subId &&
			salesforceTrackingParameters.list &&
			salesforceTrackingParameters.originalLinkId &&
			salesforceTrackingParameters.batchId != null
		) {
			const conversionTracker = new ConversionTracker(Cookies);
			conversionTracker.saveParams({
				memberId: salesforceTrackingParameters.memberId,
				jobId: salesforceTrackingParameters.jobId,
				subId: salesforceTrackingParameters.subId,
				list: salesforceTrackingParameters.list,
				originalLinkId: salesforceTrackingParameters.originalLinkId,
				batchId: salesforceTrackingParameters.batchId,
			});
		}
	}
	const ContactFormREF = createRef();
	const CookieBannerRef = createRef();
	const subSite = useMemo(() => getSubSite(data.tags), [data.tags]);

	useEffect(() => {
		pushSessionIdToDataLayer();
	}, []);

	return (
		<SubSiteContext.Provider value={subSite}>
			<Page {...data} CookieBannerRef={CookieBannerRef}>
				<Script
					dangerouslySetInnerHTML={{
						__html: OlarkChatBox,
					}}
				/>

				{data &&
					data.components &&
					data.components.map((comp: ComponentData, index: number) => (
						<React.Fragment key={`${comp.id}${index.toString()}`}>
							{comp.type === 'hero' && <Hero {...comp.data} />}
							{comp.type === 'usp' && <USP {...comp.data} />}
							{comp.type === 'divider' && <Divider {...comp.data} />}
							{comp.type === 'location' && <Location {...comp.data} />}
							{comp.type === 'amenities' && <AmenitiesTitle {...comp.data} />}
							{comp.type === 'carousel' && <Carousel {...comp.data} />}
							{comp.type === 'logogrid' && <LogoGrid {...comp.data} />}
							{comp.type === 'support' && <Support {...comp.data} />}
							{comp.type === 'multiOptionMediaCta' && (
								<MultiOptionMediaCta {...comp.data} targetRef={ContactFormREF} />
							)}
							{comp.type === 'cta' && <CTA {...comp.data} />}
							{comp.type === 'contact' && (
								<Contact {...comp.data} compRef={ContactFormREF} />
							)}
							{comp.type === 'contactpardot' && (
								<ContactPardot {...comp.data} compRef={ContactFormREF} />
							)}
							{comp.type === 'collageAndText' && <CollageAndText {...comp.data} />}
							{comp.type === 'bulletedImageCta' && (
								<BulletedImageCta {...comp.data} />
							)}
							{comp.type === 'markDownAndImage' && (
								<MarkDownAndImage {...comp.data} />
							)}
							{comp.type === 'heroSplit' && <HeroSplit {...comp.data} />}
							{comp.type === 'heroSvg' && <HeroSvg {...comp.data} />}
							{comp.type === 'gallery' && <Gallery {...comp.data} />}
							{comp.type === 'pioneer' && <Pioneer {...comp.data} />}
							{comp.type === 'tabbed' && <Tabbed {...comp.data} />}
							{comp.type === 'onward' && <Onward {...comp.data} />}
							{comp.type === 'uspText' && <USPText {...comp.data} />}
							{comp.type === 'uspList' && <USPList {...comp.data} />}
							{comp.type === 'compareTable' && <CompareTable {...comp.data} />}
							{comp.type === 'cardsBlock' && <CardsBlock {...comp.data} />}
							{comp.type === 'cards' && <Cards {...comp.data} />}
							{comp.type === 'teamBlock' && <TeamBlock {...comp.data} />}
							{comp.type === 'accordionFilter' && <AccordionFilter {...comp.data} />}
							{comp.type === 'accordion' && <Accordion {...comp.data} />}
							{comp.type === 'singleusp' && <SingleUSP {...comp.data} />}
							{comp.type === 'guidance' && <Guidance {...comp.data} />}
							{comp.type === 'package' && <Package {...comp.data} />}
							{comp.type === 'horizontalCards' && <HorizontalCards {...comp.data} />}
							{comp.type === 'stats' && <Stats {...comp.data} />}
							{comp.type === 'virtualTour' && <VirtualTourEmbed {...comp.data} />}
							{comp.type === 'heroCTA' && <HeroCTA {...comp.data} />}
							{comp.type === 'contactCards' && (
								<ContactCards {...comp.data} targetRef={ContactFormREF} />
							)}
							{comp.type === 'purpose' && <Purpose {...comp.data} />}
							{comp.type === 'officeOptions' && <OfficeOptions {...comp.data} />}
							{comp.type === 'mailOption' && <MailOption {...comp.data} />}
							{comp.type === 'mapwithcards' && <MapWithCards {...comp.data} />}
							{comp.type === 'content' && <Content {...comp.data} />}
							{comp.type === 'article' && (
								<Article {...comp.data} canonicalUrl={data.canonical} />
							)}
							{comp.type === 'contentSelection' && (
								<ContentSelection {...comp.data} />
							)}
							{comp.type === 'heroCarousel' && <HeroCarousel {...comp.data} />}
							{comp.type === 'customEmbed' && (
								<CustomEmbed {...comp.data} isArticlePage={false} />
							)}
							{comp.type === 'articleFilter' && (
								<ArticleFilter {...comp.data} subSite={subSite} />
							)}
							{comp.type === 'heroVideoImage' && <HeroVideoImage {...comp.data} />}
							{comp.type === 'bannerCtaComponent' && <BannerCta {...comp.data} />}
							{comp.type === 'dynamicArticleList' && (
								<DynamicArticleList {...comp.data} subSite={subSite} />
							)}
							{comp.type === 'sitemap' && (
								<Sitemap {...comp.data} subSite={subSite} />
							)}
						</React.Fragment>
					))}
			</Page>
		</SubSiteContext.Provider>
	);
};

export default Slug;

export const getStaticPaths: GetStaticPaths = () => ({
	paths: [],
	fallback: 'blocking',
});

function tryParseJSON(jsonString: string): any {
	try {
		const o = JSON.parse(jsonString);

		if (o) {
			return o;
		}
	} catch (e) {
		console.log('JSON parse error:', e);
		return null;
	}

	return null;
}
export const getStaticProps = async (context: any) => {
	try {
		const config = serverConfig();
		const dataPath = Array.isArray(context.params.slug)
			? `/${context.params.slug.join('/')}/`
			: '';

		type dataProps = {
			status?: HttpStatusCode;
			theme?: any;
			headers?: any;
			footers: any;
		};

		let data: dataProps = {} as dataProps;

		if (!dataPath.includes('favicon')) {
			const headers = new Headers();
			headers.set('Authorization', `Basic ${config.contentApiKey}`);

			const apiUrl = config.contentApiUrl + dataPath;

			const apiReq = await fetch(apiUrl, { headers });

			const body = await apiReq.text();
			data = tryParseJSON(body);

			if (!data) {
				console.error(`Couldn't parse JSON (response status: ${apiReq.status})`, body);
				return;
			}
			if (data?.status === HttpStatusCode.NOT_FOUND) {
				return { notFound: true };
			}

			if (data) {
				data = data[dataPath];
				const navUrl = config.contentGlobalUrl;

				if (navUrl !== undefined) {
					const navData = await fetch(navUrl, { headers });
					const nav = await navData.json();
					data = {
						...data,
						headers: nav.subnavs,
						footers: nav.footers,
					};
				}
			}
		}

		if (data?.theme !== undefined) {
			data.theme = themes[data.theme];
		}

		if (data?.theme === undefined) {
			data.theme = themes.blue1;
		}
		// todo base the refresh off publish date
		// everyDay = 86400;

		return { props: { data }, revalidate: 30 };
	} catch (error) {
		console.log(`getStaticProps ERROR - ${context.params.slug}`, error);
	}
};
