import React, { useEffect, useState } from 'react';

import { ArticleCardProps } from '../../../types';

import Dropdown, { DropdownOption } from '../../atoms/Dropdown';
import Button from '../../atoms/Button';
import ArticleCard from '../../molecules/ArticleCard';
import Styled from './index.styled';

export type ContentProps = {
	types: DropdownOption[];
	authors: DropdownOption[];
	locations: DropdownOption[];
};

type Article = {
	articleId: string;
} & ArticleCardProps;

const Content = (props: ContentProps) => {
	const { types, authors, locations } = props;

	const ARTICLES_COUNT = 6;

	const [type, setType] = useState('all');
	const [author, setAuthor] = useState('all');
	const [location, setLocation] = useState('all');
	const [page, setPage] = useState(1);

	const [loading, setLoading] = useState(false);
	const [error, setError] = useState<boolean>();
	const [articles, setArticles] = useState<Article[] | []>([]);
	const [hasMore, setHasMore] = useState(true);

	const loadArticles = async () => {
		const url = new URL('/api/articles', window.location.origin);

		url.searchParams.append('type', type);
		url.searchParams.append('author', author);
		url.searchParams.append('location', location);
		// eslint-disable-next-line no-unused-expressions
		page && url.searchParams.append('page', page.toString());

		setLoading(true);
		setError(false);

		try {
			const response = await fetch(url.toString());

			if (!response.ok) {
				throw new Error('Failed to search for articles');
			}

			const json = await response.json();

			setArticles([...articles, ...json.articles]);
			setPage(json.page);
			if (json.articles.length < ARTICLES_COUNT) {
				setHasMore(false);
			}
		} catch (e) {
			console.error(e);
			setError(true);
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		loadArticles();
	}, [type, author, location]);

	const handleChange = evt => {
		const { name } = evt.target;
		const { value } = evt.target;

		setArticles([]);
		setHasMore(true);

		switch (name) {
			case 'type':
				setType(value);
				break;
			case 'author':
				setAuthor(value);
				break;
			case 'location':
				setLocation(value);
				break;
			default:
				console.log('Unknown name');
				break;
		}
	};

	return (
		<>
			<Styled.Content__Filters>
				<Styled.Content__Filters__Inner>
					<Styled.Content__Title>Filter results by:</Styled.Content__Title>
					<Dropdown
						id="type"
						label="Article type"
						name="type"
						options={types}
						onChange={handleChange}
					/>
					<Dropdown
						id="author"
						label="Author"
						name="author"
						options={authors}
						onChange={handleChange}
					/>
					<Dropdown
						id="location"
						label="Location"
						name="location"
						options={locations}
						onChange={handleChange}
					/>
				</Styled.Content__Filters__Inner>
			</Styled.Content__Filters>

			{!error && (
				<Styled.Content__Articles as="section">
					{articles.length > 0 && (
						<Styled.Content__Articles_Grid as="section">
							{articles.map((article: Article) => (
								<ArticleCard key={article.articleId} {...article} />
							))}
						</Styled.Content__Articles_Grid>
					)}
					{!loading && articles.length === 0 && (
						<Styled.Content__Articles__Message>
							No Articles matched the selected filters
						</Styled.Content__Articles__Message>
					)}
				</Styled.Content__Articles>
			)}

			{error && (
				<Styled.Content__Articles as="section">
					<Styled.Content__Articles__Message>
						Oops, something went wrong. Please try again.
					</Styled.Content__Articles__Message>
				</Styled.Content__Articles>
			)}

			{hasMore && (
				<Styled.Content_LoadMore as="section">
					<Button
						buttonText={!error ? 'Load more' : 'Try Again'}
						buttonType="tertiary"
						callback={() => {
							loadArticles();
							setPage(page + 1);
						}}
						loadingText="Loading"
						loading={loading}
					/>
				</Styled.Content_LoadMore>
			)}
		</>
	);
};

export default Content;
